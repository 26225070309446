<template>
  <card class="goods-info">
    <AdminTitle title="订单信息" size="20px"> </AdminTitle>
    <div class="goods-info-detail">
      <el-image
        style="
          width: 100px;
          border-radius: 8px;
          height: 100px;
          margin-right: 20px;
        "
        :src="
          detailInfo.orderGoods ? detailInfo.orderGoods.skuCoverImageUrl : ''
        "
        :preview-src-list="[
          detailInfo.orderGoods ? detailInfo.orderGoods.skuCoverImageUrl : '',
        ]"
      >
        <div
          style="
            background: #f5f7fa;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            color: #c0c4cc;
          "
          slot="error"
        >
          暂无图片
        </div>
      </el-image>
      <div class="goods-info-detail-right">
        <div class="goods-title fw700">
          {{ detailInfo.orderGoods ? detailInfo.orderGoods.skuTitle : "" }}
        </div>
        <div class="goods-tag">
          <el-tag
            :type="
              detailInfo.orderGoods && detailInfo.orderGoods.goodsPurityName
                ? detailInfo.orderGoods.goodsPurityName == '全新'
                  ? 'success'
                  : 'warning'
                : 'success'
            "
            >{{
              detailInfo.orderGoods ? detailInfo.orderGoods.goodsPurityName : ""
            }}</el-tag
          >
          <el-tag type="success">{{ detailInfo.orderTypeName }}</el-tag>
          <el-tag type="danger" v-if="detailInfo.enableAlipayFundAuth"
            >免押</el-tag
          >
          <el-tag type="info">{{ detailInfo.statusName }}</el-tag>
        </div>
        <div class="label">订单号：{{ $route.params.id }}</div>
        <div class="label">下单时间：{{ detailInfo.createTime }}</div>
      </div>
    </div>
    <div class="cost-details">
      <div class="cost-title">费用明细</div>
    </div>
    <card class="goods-info special-card">
      <el-row>
        <el-col
          :span="item.width ? item.width : 8"
          v-for="(item, index) in goodsInfoList"
          :key="index"
        >
          <span class="label">{{ item.label }}</span>
          <span class="value"
            >{{ detailInfo[item.prop] || "" }}{{ item.unit }}</span
          >
          <!-- 费率 -->
          <i
            v-if="
              item.edit &&
              item.prop == 'storeComboName' &&
              (detailInfo.orderType == 2 || detailInfo.orderType == 4) &&
              isShowBtn(AUTH_BTN.review_list_detail_update_price_rate)
            "
            @click="updateOrderCombo"
            class="el-icon-edit"
          >
            修改</i
          >
          <!-- 零售价 -->
          <i
            v-if="
              item.edit &&
              item.prop == 'salePrice' &&
              isShowBtn(AUTH_BTN.review_list_detail_update_cost_price)
            "
            @click="updatePrice"
            class="el-icon-edit"
          >
            修改</i
          >
        </el-col>
      </el-row>
    </card>

    <el-dialog title="费率" :visible.sync="priceRateDialog" width="800px">
      <div class="fei-box">
        <div class="fei-item" v-for="(item, i) in storeComboList" :key="i">
          <div class="title">等级{{ item.gradeType }}</div>
          <el-radio-group v-model="storeComboData.storeComboId">
            <el-radio
              style="margin-bottom: 10px"
              v-for="(item, i) in item.comboList"
              :key="i"
              :label="item.id"
              >{{ item.comboName }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="priceRateDialog = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="savePriceRate" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 零售价 -->
    <el-dialog title="修改零售价" :visible.sync="priceDialog" width="350px">
      <el-form ref="periodForm" :model="priceData" label-width="80px">
        <el-form-item label="新零售价">
          <el-input-number
            style="width: 200px"
            v-model="priceData.salePrice"
            size="small"
            :precision="2"
            :min="0"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="priceDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="savePrice" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <EditCombo
      :orderNo="detailInfo.orderNo"
      :comboId="detailInfo.storeComboId"
      :comboRoute="detailInfo.storeComboRoute"
      :rewardComboNum="detailInfo.store?.rewardComboNum"
      :visible.sync="editComboProps.visible"
      @refresh="refresh"
    />
  </card>
</template>

<script>
import {
  getStoreComboListAPI,
  updateStoreComboAPI,
  updatePriceAPI,
} from "./api";
import { authBtnMixin } from "@/mixins/authBtnMixin";
import EditCombo from "./edit-combo/index.vue";

export default {
  components: { EditCombo },
  name: "OrderInfo",
  props: {
    detailInfo: {
      type: Object,
      default: () => {},
    },
    flag: {
      type: Boolean,
      default: true,
    },
  },
  inject: ["reload"],
  mixins: [authBtnMixin],
  data() {
    return {
      priceData: {
        salePrice: "",
        orderNo: "",
      },
      priceRateDialog: false,
      storeComboList: [],
      storeComboData: {
        orderNo: "",
        storeComboId: "",
      },
      goodsInfoList: [
        {
          label: "总租金",
          prop: "rentPrice",
          unit: "元",
        },
        {
          label: "买断金",
          prop: "buyoutPrice",
          unit: "元",
        },
        {
          label: "方案",
          prop: "storeComboName",
          edit: true,
        },
        {
          label: "首付租金",
          prop: "prepayRentPrice",
          unit: "元",
        },
        {
          label: "监管锁费",
          prop: "lockAmount",
          unit: "元",
        },
        {
          label: "零售价",
          prop: "salePrice",
          unit: "元",
          edit: true,
        },
        {
          label: "租期",
          prop: "billPeriodNum",
          unit: "期",
        },
        {
          label: "平台服务费",
          prop: "servicePrice",
          unit: "元",
          width: 16,
        },
        {
          label: "",
          prop: "",
          unit: "",
        },
        {
          label: "保险金",
          prop: "insurancePrice",
          unit: "元",
        },
      ],
      updateFinalGradeDialog: false,
      priceDialog: false,
      editComboProps: {
        visible: false,
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {},

  methods: {
    // 保存零售价
    savePrice() {
      updatePriceAPI(this.priceData).then(() => {
        localStorage.setItem("is_update_period", "true");
        this.$emit("update:flag", false);
        this.$emit("updateDetail");
        this.$message.success("修改成功");
        this.priceDialog = false;
      });
    },
    // 打开修改零售价
    updatePrice() {
      this.priceData.orderNo = this.$route.params.id;
      this.priceData.salePrice = this.detailInfo.salePrice;
      this.priceDialog = true;
    },
    // 保存修改的服务费率
    savePriceRate() {
      updateStoreComboAPI(this.storeComboData).then(() => {
        localStorage.setItem("is_update_period", "true");
        this.$emit("update:flag", false);
        this.$emit("updateDetail");
        this.$message.success("修改成功");
        this.priceRateDialog = false;
      });
    },
    // 打开修改服务费率窗口
    updateOrderCombo() {
      if (!this.detailInfo.orderScoreProfile?.finalHitGrade) {
        return this.$message.error("请先完成用户评级");
      }

      this.editComboProps.visible = true;
    },
    // 门店套餐列表
    async getStoreComboList() {
      let param = {
        comboGrade: this.detailInfo.orderScoreProfile?.finalHitGrade,
      };
      this.storeComboList = await getStoreComboListAPI(param);
    },
    refresh() {
      this.$emit("updateDetail");
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-info /deep/ {
  border-color: transparent;
  margin-top: 20px;
  width: 100%;
  border-radius: 16px;
  color: #222222;
  .label {
    color: #666666;
    font-size: 14px;
  }
  .ivu-card {
    border-radius: 18px;
  }
  .goods-info-detail {
    display: flex;
    .goods-info-detail-right {
      display: flex;
      flex-direction: column;
      .goods-title {
        color: #333333;
        font-size: 16px;
      }
      .goods-tag {
        margin-top: 8px;
      }
      .label {
        margin-top: 8px;
        font-size: 12px;
      }
    }
    .el-tag {
      height: 25px;
      border-radius: 15px;
      margin-right: 10px;
      line-height: 25px;
    }
    .el-tag.el-tag--success {
      background-color: #fff;
      color: #666666;
      border-color: #e4e4e4;
    }

    .el-tag.el-tag--warning {
      background-color: #fff;
      color: #f15f5f;
      border-color: #f15f5f;
    }
    .el-tag.el-tag--info {
      background-color: #fff;
      color: #ffac46;
      border-color: #ffac46;
    }
  }
  .cost-details {
    .cost-title {
      margin-top: 15px;
      color: #666666;
      font-size: 14px;
    }
  }
  .el-dialog__footer {
    padding-bottom: 30px !important;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-style {
      color: #333333;
      flex-wrap: 600;
      font-size: 16px;
    }
    .supple-inform-edit {
      cursor: pointer;
      background: #f7f8fa;
      color: #333333;
      padding: 8px 18px;
      border-radius: 45px;
    }
  }

  .update-final-grade-dialog {
    .el-dialog__footer {
      padding-top: 0;
    }
  }
}
.special-card {
  background: #f7f8fa;
  line-height: 35px;
  color: #222222;
  position: relative;
  .value {
    margin-left: 15px;
    min-width: 80px;
    display: inline-block;
    font-weight: 700;
  }
  .label {
    text-align: right;
    min-width: 80px;
    display: inline-block;
  }
  .el-icon-edit {
    margin-left: 30px;
    cursor: pointer;
    color: #4badf8;
  }
  &::after {
    content: "";
    display: inline-block;
    height: 80%;
    width: 1px;
    border-left: 1px dashed #e4e4e4;
    right: 33%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
}
.fei-box {
  .fei-item {
    margin-bottom: 10px;
    .title {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}
.fw700 {
  font-weight: 700;
}
</style>
